import React, { lazy } from "react";
import LazyLoad from "react-lazyload";
import Image from "shared/components/Image";
const Skeleton = lazy(() => import("react-loading-skeleton"));

export function QuizCard(props) {
  return (
    <div className="card border-0 rounded shadow-xs justify-content-between w-100">
      <div className="card-img-top d-flex justify-content-center align-items-top flex-column">
        <LazyLoad height={170} minHeight={170}>
          <div className="overflow-hidden">
            <div
              style={{
                width: "100%",
                height: "100%",
                minWidth: "auto",
                minHeight: "160px",
                maxHeight: "160px",
                borderTopLeftRadius: "13px",
                borderTopRightRadius: "13px",
                marginTop: "0px",
              }}>
              <Image
                src={process.env.REACT_APP_API_URL + props.quiz.image.url}
                thumb={`assets/images/placeholder-${Math.floor(
                  Math.random() * 3
                ) + 1}.png`}
                alt={props.quiz.title}
              />
            </div>
          </div>
        </LazyLoad>
        {props.quiz.type === "numerical" ? (
          <span
            className="badge badge-pill badge-warning mt-n3 px-4 mx-3"
            style={{
              paddingTop: "6px",
              paddingBottom: "6px",
              fontSize: "11px",
              zIndex: "0",
            }}>
            تحدي أعلى نتيجة
          </span>
        ) : (
          <span
            className="badge badge-pill badge-primary mt-n3 px-4 mx-3"
            style={{
              paddingTop: "6px",
              paddingBottom: "6px",
              fontSize: "11px",
              zIndex: "0",
            }}>
            تحدي التصنيف
          </span>
        )}
      </div>
      <span
        className="text-dark text-center my-auto p-1"
        style={{ fontSize: "12px" }}>
        {props.quiz.title || <Skeleton />}
      </span>
    </div>
  );
}

export default QuizCard;
