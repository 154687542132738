import React from "react";
const Image = props => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  return (
    <React.Fragment>
            <img
        className="image thumb"
        alt={props.alt}
        src={props.thumb}
        style={{ display: isLoaded ? "none" : "block" }}
      />
            <img
        onLoad={() => {
          setIsLoaded(true);
        }}
        className="image full"
        style={{ opacity: isLoaded ? 1 : 0}}
        alt={props.alt}
        src={props.src}
      />
    </React.Fragment>
  );
};
export default Image;